$('body').on('click', '[configurator-target]', function(e) {
	e.preventDefault();

	let section = $(this).attr('configurator-target');

	$(`[configurator-section]`).hide();
	$(`[configurator-section=${section}]`).fadeIn();
});

$(document).ready(function() {
    $('.kitchen-swiper-card').click(function(e) {
        $('.kitchen-swiper').removeClass('active');
        $('.kitchen-swiper-card.active').removeClass('active');

        var $parent = $(this).parent();
        $parent.addClass('active');
        e.preventDefault();
    });
});

$('.kitchen-swiper-card').on('click', function() {
    $('.configurator-section').hide();
	let target = $(this).attr('configurator-show');
    $(target).fadeIn();
});

$(".kitchen-swiper-card").click(function(e) {
    e.preventDefault();
    var aid = $(this).attr("href");
    $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
});

$(".booking-scroll").click(function(e) {
    scrollTop: $(window).scrollTop() + 100
});

$(function () {
    $(".input-group.date").datepicker({ 
        autoclose: true, 
        singleDatePicker: true,
        showDropdowns: true,
        format: "dd.mm.yyyy"
    })
});