jQuery(document).ready(function($){
    AOS.init({disable: 'mobile'});

    /** available **/
    $('.class_checkbox_available').on('click',function(){
        $(this).toggleClass('checked').prev().prop('checked',$(this).is('.checked'))
    });

    /** reserved **/
    $('.class_checkbox_reserved').on('click',function(){
        $(this).toggleClass('checked').prev().prop('checked',$(this).is('.checked'))
    });

    /** sold **/
    $('.class_checkbox_sold').on('click',function(){
        $(this).toggleClass('checked').prev().prop('checked',$(this).is('.checked'))
    });

    /** soon **/
    $('.class_checkbox_soon').on('click',function(){
        $(this).toggleClass('checked').prev().prop('checked',$(this).is('.checked'))
    });

    /** hold **/
    $('.class_checkbox_hold').on('click',function(){
        $(this).toggleClass('checked').prev().prop('checked',$(this).is('.checked'))
    });
});

/**
 *  Display success/error modal on newsletter enquiry submission
 */
// $('#enquiry-submit').on('click', function(e) {
//     e.preventDefault();
//     $.ajax({
//         headers: {
//           'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
//         },
//         url: '/enquire',
//         type: 'post',
//         data: $('#enquire-form').serializeArray(),
//         success: function(data) {
//             if (data[0] === 'success') {
//                 $('#success-modal .modal-body').html(data[1]);
//                 $('#success-modal').modal('toggle');
//                 setTimeout(function(){
//                   location.reload();
//                 }, 500);
//             } else if (data[0] === 'error') {
//                 $('#error-modal .modal-body').html(data[1]);
//                 $('#error-modal').modal('toggle');
//                  recaptchaRefresh();
//             }
//         },
//         error: function(error) {
//             $('#error-modal .modal-body').html(error);
//             $('#error-modal').modal('toggle');
//         }
//     });
// });