var swiper1 = new Swiper('.swiper-container.testimonials', {
    spaceBetween: 30,
        effect: 'fade',
        autoplay: {
           delay: 5000,
        },
        pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});
var swiper1 = new Swiper('.specification-swiper-mobile', {
    spaceBetween: 30,
    slidesPerView: 1,
    autoplay: {
       delay: 5000,
    },
    pagination: {
        el: '.specification-swiper-mobile-pagination',
        clickable: true,
    },
    navigation: {
      nextEl: '.specification-swiper-mobile-next',
      prevEl: '.specification-swiper-mobile-prev',
    },
});
var swiper1 = new Swiper('.swiper-container.hero-swiper', {
    spaceBetween: 30,
        effect: 'fade',
        autoplay: {
           delay: 5000,
        },
        pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
});
var swiper2 = new Swiper('.text-image-swiper .swiper-container', {
    spaceBetween: 30,
        effect: 'fade',
        pagination: {
        clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next-single',
      prevEl: '.swiper-button-prev-single',
    },
});

var swiper3 = new Swiper('.local-area-swiper .swiper-container', {
    spaceBetween: 30,
        effect: 'fade',
        pagination: {
        clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next-single',
      prevEl: '.swiper-button-prev-single',
    },
});

var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 30,
        slidesPerView: 5,
        allowTouchMove: false,
        // freeMode: true,
        // loop: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        breakpoints: {
            2000: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
            1800: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
            1500: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 3,
                spaceBetween: 10,
            },
            320: {
                slidesPerView: 3,
                spaceBetween: 10,
            }
        },
    });
    var galleryTop5 = new Swiper('.gallery-top', {
      spaceBetween: 10,
      effect: 'fade',
      navigation: {
        nextEl: '.swiper-button-next-gallery-thumbs',
        prevEl: '.swiper-button-prev-gallery-thumbs',
      },
      thumbs: {
        swiper: galleryThumbs
      }
});

    var mySwiper6 = new Swiper('.swiper-container.nearby-slider', {
        slidesPerView: 2,
        spaceBetween: 100,
        slidesPerGroup: 1,
        loop: true,
        autoplay: {
            delay: 4000,
        },
        loopFillGroupWithBlank: true,
        scrollbar: {
            el: '.swiper-scrollbar',
            hide: false,
        },
        navigation: {
            nextEl: '.swiper-button-next-nearby',
            prevEl: '.swiper-button-prev-nearby',
        },
        breakpoints: {
            2000: {
                slidesPerView: 2,
                spaceBetween: 60,
                slidesPerGroup: 1,
            },
            1800: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 1,
            },
            1500: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 1,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 30,
                slidesPerGroup: 1,
            },
            992: {
                slidesPerView: 1,
                spaceBetween: 30,
                slidesPerGroup: 1,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20,
                slidesPerGroup: 1,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerGroup: 1,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10,
                slidesPerGroup: 1,
            }
        },
    });

var mySwiper6 = new Swiper('.swiper-container.offset-slider', {
    slidesPerView: 3,
    spaceBetween: 100,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
        delay: 4000,
    },
    loopFillGroupWithBlank: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next-offset',
        prevEl: '.swiper-button-prev-offset',
    },
    breakpoints: {
        2000: {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        1500: {
            slidesPerView: 3,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        992: {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        }
    },
});

var mySwiper7 = new Swiper('.swiper-container.inspiration-slider', {
    slidesPerView: 3,
    spaceBetween: 100,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
        delay: 2500,
    },
    loopFillGroupWithBlank: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next-inspiration',
        prevEl: '.swiper-button-prev-inspiration',
    },
    breakpoints: {
        2000: {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1500: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        992: {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        }
    },
});

var mySwiper8 = new Swiper('.swiper-container.gallery-slider', {
    slidesPerView: 2,
    spaceBetween: 100,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
        delay: 4000,
    },
    loopFillGroupWithBlank: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next-gallery',
        prevEl: '.swiper-button-prev-gallery',
    },
    breakpoints: {
        2000: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1800: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1500: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        992: {
            slidesPerView: 1,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        }
    },
})

var mySwiper9 = new Swiper('.swiper-container.inspiration-gallery-slider', {
    slidesPerView: 3,
    spaceBetween: 100,
    slidesPerGroup: 1,
    loop: true,
    autoplay: {
        delay: 4000,
    },
    loopFillGroupWithBlank: true,
    scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
    },
    navigation: {
        nextEl: '.swiper-button-next-inspiration-gallery',
        prevEl: '.swiper-button-prev-inspiration-gallery',
    },
    breakpoints: {
        2000: {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1800: {
            slidesPerView: 3,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1500: {
            slidesPerView: 2,
            spaceBetween: 60,
            slidesPerGroup: 1,
        },
        1200: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        992: {
            slidesPerView: 2,
            spaceBetween: 30,
            slidesPerGroup: 1,
        },
        768: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1,
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        },
        320: {
            slidesPerView: 1,
            spaceBetween: 10,
            slidesPerGroup: 1,
        }
    },
})

var swiper10 = new Swiper('.swiper-container.single-swiper', {
  spaceBetween: 10,
  effect: 'fade',
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next-single',
    prevEl: '.swiper-button-prev-single',
  },
});

var swiper = new Swiper('.swiper-container.single-swiper', {
  spaceBetween: 10,
  effect: 'fade',
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
// $('.kitchen-swiper').owlCarousel({
//     nav: true,
//     pagination: false,
//     margin:15,
//        responsive:{
//         0:{
//             items:1
//         },
//         600:{
//             items:3
//         },
//         750:{
//             items:4
//         },
//         1000:{
//             items:4
//         }
//     }
// })
$('.development-swiper').owlCarousel({
    nav: false,
    pagination: false,
    margin:15,
       responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        750:{
            items:3
        },
        1000:{
            items:3
        }
    }
})

// $('.kitchen-swiper').slick({
//   infinite: false,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 4,
//         slidesToScroll: 3,
//         infinite: false,
//         dots: true
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//       }
//     }
//     // You can unslick at a given breakpoint now by adding:
//     // settings: "unslick"
//     // instead of a settings object
//   ]
// });

// $('.development-swiper').slick({
//   infinite: true,
//   slidesToShow: 4,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 4,
//         slidesToScroll: 3,
//         infinite: true,
//         dots: true
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1
//       }
//     }
//   ]
// });



$('.configurator-element-slider-2').slick({
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
});





$('#developmentConfigNext').on('click', function() {
    $('.development-swiper').slick('refresh');
});

$('.development-swiper-card').on('click', function() {
    console.log('test');
    $('.kitchen-swiper').slick('refresh');
});

$('.configurater-element-item').on('click', function() {
    console.log('test1212');
    $('.configurator-element-slider').slick('refresh');
    initSwiper();
});

$('.kitchen-swiper-card').on('click', function() {
    console.log('test');
    $('.configurator-element-slider-2').slick('refresh');
});


