let featuredPlot = $(`[plot-featured=1]`).first();
let availablePlot = $(`[plot-status=available]`).first();
let ignoreAuto = $(`[siteplan-auto-ignore]`).first();


if(ignoreAuto.length == 0) {
	if(featuredPlot.length > 0) {
		featuredPlot.click();
	} else if(availablePlot.length > 0) {
		availablePlot.click();
	}
}

$('body').on('click', '[housetype-siteplan-toggle]', function() {
	console.log('clicked');
	let housetype = $(this).attr('housetype-siteplan-toggle');

	$(`[siteplan-filter="housetype"] option[value=${housetype}]`).prop('selected', true);
	$(`[siteplan-filter="status"] option[value=all]`).prop('selected', true);
	$(`[siteplan-filter="housetype"]`).trigger('change');

	let featuredPlot = $(`[plot-housetype=${housetype}][plot-featured=1]`).first();
	let availablePlot = $(`[plot-housetype=${housetype}][plot-status=available]`).first();
	let firstPlot = $(`[plot-housetype=${housetype}]`).first();

	if(featuredPlot.length > 0) {
		featuredPlot.click();
	} else if(availablePlot.length > 0) {
		availablePlot.click();
	} else if(firstPlot.length > 0) {
		firstPlot.click();
	}

});