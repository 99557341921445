$('body').on('submit', '[ajax-form=true]', function(e){
	e.preventDefault();

	var url = $(this).attr('action');
	var type = $(this).attr('method');
	var data = new FormData(this);
	var dataRefresh1 = $(this).attr('data-refresh-1');
	var dataRefresh2 = $(this).attr('data-refresh-2');
	var dataRefresh3 = $(this).attr('data-refresh-3');

	let currentUrl = '//' + location.host + location.pathname;
	var queryString = new URLSearchParams(data).toString()
	let newUrl = currentUrl+'?'+queryString;
	window.history.replaceState(null, null, newUrl);

	$.ajax({
		url: url,
		type: type,
		data: data,
		success: function(){
			$('.modal').modal('hide');
			$(dataRefresh1).load(location.href+' '+dataRefresh1+'>*','');
			$(dataRefresh2).load(location.href+' '+dataRefresh2+'>*','');
			$(dataRefresh3).load(location.href+' '+dataRefresh3+'>*','');
			// $('[ajax-form=true]').trigger('reset');
		},
		cache: false,
        contentType: false,
        processData: false
	})
});2